import { isHls } from "@utils/files";
import { StyleSheet, css } from "aphrodite";
import Hls, { Events, ManifestLoadedData } from "hls.js";
import {
  FC,
  RefObject,
  VideoHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from "react";

type Props = VideoHTMLAttributes<HTMLVideoElement> & {
  hideControls?: boolean;
  playOnHover?: boolean;
  _ref?: RefObject<HTMLVideoElement>;
  language?: Language;
};

const VideoPlayer: FC<Props> = (props) => {
  const _ref = useRef<HTMLVideoElement>(null);
  const ref = props._ref || _ref;
  const loadHls = useCallback((): Hls | null => {
    const video = ref.current;
    if (!Hls.isSupported() || !video) {
      return null;
    }
    const hls = new Hls();
    hls.loadSource(props.src || "");
    hls.attachMedia(video);
    const setSubtitleTrack = (
      _: Events.MANIFEST_LOADED,
      data: ManifestLoadedData,
    ) => {
      const subtitleTracks = data?.subtitles || [];
      const subtitleIndex = subtitleTracks.findIndex(
        (s) => s.lang === (props.language || "en") && s.type === "SUBTITLES",
      );
      hls.subtitleTrack = subtitleIndex;
    };
    hls.on(Hls.Events.MANIFEST_LOADED, setSubtitleTrack);
    return hls;
  }, [props.src, props.language, ref]);
  useEffect(() => {
    let hls: Hls | null;
    let regenAttempts = 0;
    if (!props.src) {
      console.warn("src not supplied to VideoPlayer");
      return;
    }
    if (ref.current) {
      const video = ref.current;
      if (
        !isHls(props.src) ||
        video.canPlayType("application/vnd.apple.mpegurl")
      ) {
        video.src = props.src;
      } else if (Hls.isSupported()) {
        const regenHls = () => {
          if (hls) {
            hls.destroy();
          }
          if (regenAttempts > 5) {
            return;
          }
          regenAttempts++;
          hls = loadHls();
          hls?.on(Hls.Events.ERROR, () => {
            setTimeout(() => {
              regenHls();
            }, 1000);
          });
        };
        regenHls();
      }
    }
    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [ref, props.src, props.language, loadHls]); // eslint-disable-line react-hooks/exhaustive-deps
  const onMouseEnter = useCallback(() => {
    ref.current?.play();
  }, [ref]);
  const onMouseLeave = useCallback(() => {
    ref.current?.pause();
  }, [ref]);
  return (
    <video
      ref={ref}
      className={css(props.hideControls && styles.hideControls)}
      {...props}
      onMouseEnter={props.playOnHover ? onMouseEnter : undefined}
      onMouseLeave={props.playOnHover ? onMouseLeave : undefined}
    />
  );
};

export default VideoPlayer;

const styles = StyleSheet.create({
  hideControls: {
    "&::-webkit-media-controls": {
      display: "none",
    },
  },
});
