import * as React from "react";

import { cn } from "@src/ui/lib/utils";
import { textVariants } from "@src/ui/text";
import { Label } from "@ui/label";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, description, ...props }, ref) => {
    return (
      <div
        className={cn(
          "flex flex-col w-full gap-1.5",
          props.disabled && "cursor-not-allowed",
          className,
        )}
      >
        {label && (
          <Label className={cn(props.disabled && "opacity-50")}>{label}</Label>
        )}
        <input
          type={type}
          className={cn(
            "flex h-9 self-stretch rounded-lg border border-input bg-transparent px-3 py-1 shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            textVariants({ variant: "P2" }),
          )}
          ref={ref}
          {...props}
        />
        {description && (
          <p
            className={cn(
              textVariants({ variant: "P3" }),
              "text-gray-8",
              props.disabled && "opacity-50",
            )}
          >
            {description}
          </p>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
