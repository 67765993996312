import { FC } from "react";
// @ts-ignore
import ReactGiphySearchbox from "react-giphy-searchbox";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGIFSelect: (gif: any) => void;
};

const GIFs: FC<Props> = (props) => {
  return (
    <ReactGiphySearchbox
      apiKey={process.env.NEXT_PUBLIC_GIPHY_API_KEY}
      searchFormClassName="[&>input]:flex [&>input]:h-9 [&>input]:self-stretch [&>input]:rounded-lg [&>input]:border [&>input]:border-input [&>input]:bg-transparent [&>input]:px-3 [&>input]:py-1 [&>input]:shadow-sm [&>input]:transition-colors [&>input]:file:border-0 [&>input]:file:bg-transparent [&>input]:file:text-sm [&>input]:file:font-medium [&>input]:placeholder:text-muted-foreground [&>input]:focus-visible:outline-none [&>input]:focus-visible:ring-1 [&>input]:focus-visible:ring-ring [&>input]:disabled:cursor-not-allowed [&>input]:disabled:opacity-50 [&>input]:text-[14px] [&>input]:tracking-[-0.006em] [&>input]:font-normal w-[473px]"
      wrapperClassName="h-full w-[473px]"
      listWrapperClassName="w-full w-[473px] overflow-x-hidden"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSelect={props.onGIFSelect}
      masonryConfig={[{ columns: 4, imageWidth: 110, gutter: 10 }]}
      gifListHeight="90%"
      gifPerPage={50}
      searchPlaceholder="Search GIFs from Giphy"
    />
  );
};

export default GIFs;
