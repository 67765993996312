import { Input } from "@src/ui/input";
import { MediaFragment } from "@src/fragments.generated";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import { FC, useMemo, useState } from "react";
import gql from "graphql-tag";
import {
  useAddMediaResourcesTrainingResourcesQuery,
  AddMediaResources_TrainingResourceFragment,
} from "./Resources.generated";
import Text from "@src/ui/text";
import { useOpenGraphDataQuery } from "@src/components/ui/MediaPreview.generated";
import { getMediaUrl } from "@src/utils/media";
import { getLinkType, LinkType } from "@src/utils/files";
import VideoPlayer from "@src/deprecatedDesignSystem/components/VideoPlayer";
import PhotoOutlineIcon from "@src/ui/icons/18px/photo-outline";
import LinkIcon from "@src/ui/icons/18px/link";
import VolumeUpOutlineIcon from "@src/ui/icons/18px/volume-up-outline";
import VideoOutlineIcon from "@src/ui/icons/18px/video-outline";
import UserOutlineIcon from "@src/ui/icons/18px/user-outline";
import FileTextOutlineIcon from "@src/ui/icons/18px/file-text-outline";
import CircleQuestionOutlineIcon from "@src/ui/icons/18px/circle-question-outline";
import { Skeleton } from "@src/ui/skeleton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@src/ui/select";
import { compareDesc, parseISO } from "date-fns";

type FileType = "All" | "PDFs" | "Images" | "Videos";

type Sort = "Date Added" | "Name" | "Type";

type Props = {
  onResourceClick: (media: MediaFragment) => void;
  filterResources?: LinkType[];
};

const FILE_TYPE_SORT_ORDER: FileType[] = ["PDFs", "Images", "Videos", "All"];

function getFileType(tr: AddMediaResources_TrainingResourceFragment): FileType {
  const mediaUrl = tr.media.mediaUrls.en;
  const linkType = getLinkType(mediaUrl);
  if (linkType === LinkType.PDF) {
    return "PDFs";
  }
  if ([LinkType.GIF, LinkType.IMAGE].includes(linkType)) {
    return "Images";
  }
  if (
    [LinkType.VIDEO, LinkType.HOSTED_VIDEO, LinkType.MUX_VIDEO].includes(
      linkType,
    )
  ) {
    return "Videos";
  }
  return "All";
}

const Loading: FC = () => <Skeleton className="h-[128px] w-[107px] p-2" />;

const Resources: FC<Props> = (props) => {
  const [fileType, setFileType] = useState<FileType>("All");
  const [sort, setSort] = useState<Sort>("Date Added");
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const { data, loading } = useAddMediaResourcesTrainingResourcesQuery({
    variables: {
      search: debouncedSearch,
    },
  });
  const resources: AddMediaResources_TrainingResourceFragment[] = useMemo(
    () => data?.TrainingResources || [],
    [data?.TrainingResources],
  );
  const visibleResources: AddMediaResources_TrainingResourceFragment[] =
    useMemo(
      () =>
        resources.filter((r) => {
          const mediaUrl = r.media.mediaUrls.en;
          const currentFileType = getFileType(r);
          let filter = true;
          if (props.filterResources) {
            filter =
              filter && props.filterResources?.includes(getLinkType(mediaUrl));
          }
          if (fileType !== "All") {
            if (fileType === "PDFs") {
              filter = filter && currentFileType === "PDFs";
            }
            if (fileType === "Images") {
              filter = filter && currentFileType === "Images";
            }
            if (fileType === "Videos") {
              filter = filter && currentFileType === "Videos";
            }
          }
          return filter;
        }),
      [props.filterResources, resources, fileType],
    );
  const sortedResources: AddMediaResources_TrainingResourceFragment[] = useMemo(
    () =>
      visibleResources.sort(
        (
          a: AddMediaResources_TrainingResourceFragment,
          b: AddMediaResources_TrainingResourceFragment,
        ) => {
          if (sort === "Date Added") {
            return compareDesc(
              parseISO(a.libraryItem.createdAt),
              parseISO(b.libraryItem.createdAt),
            );
          }
          if (sort === "Name") {
            return a.media.name?.en?.localeCompare(b.media.name?.en || "") || 0;
          }
          if (sort === "Type") {
            const aType = getFileType(a);
            const bType = getFileType(b);
            return (
              FILE_TYPE_SORT_ORDER.indexOf(aType) -
                FILE_TYPE_SORT_ORDER.indexOf(bType) || 0
            );
          }
          return 0;
        },
      ),
    [visibleResources, sort],
  );
  return (
    <>
      <Input
        placeholder="Search resources..."
        className="mb-2 w-full"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="mb-2 flex gap-1">
        <Select
          defaultValue="All"
          value={fileType}
          onValueChange={(v) => setFileType(v as FileType)}
        >
          <SelectTrigger className="mr-1">
            <>
              <span className="text-muted-foreground">File type:</span>
              <span className="mx-1">{fileType}</span>
            </>
          </SelectTrigger>
          <SelectContent className="z-[1000]">
            <SelectItem value={"All"}>All</SelectItem>
            <SelectItem value={"PDFs"}>PDFs</SelectItem>
            <SelectItem value={"Images"}>Images</SelectItem>
            <SelectItem value={"Videos"}>Videos</SelectItem>
          </SelectContent>
        </Select>
        <Select
          defaultValue="Date Added"
          value={sort}
          onValueChange={(v) => setSort(v as Sort)}
        >
          <SelectTrigger>
            <>
              <span className="text-muted-foreground">Sort by:</span>
              <span className="mx-1">{sort}</span>
            </>
          </SelectTrigger>
          <SelectContent className="z-[1000]">
            <SelectItem value={"Date Added"}>Date Added</SelectItem>
            <SelectItem value={"Name"}>Name</SelectItem>
            <SelectItem value={"Type"}>Type</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="flex max-h-full w-full flex-wrap justify-start gap-2 overflow-y-auto">
        {loading && (
          <>
            {Array.from({ length: 11 }).map((_, i) => (
              <Loading key={`add-media-loading-${i}`} />
            ))}
          </>
        )}
        {!loading &&
          sortedResources.length > 0 &&
          sortedResources.map((tr) => (
            <Resource
              key={`modal-file-list-${tr.media.id}`}
              onClick={() => props.onResourceClick(tr.media)}
              media={tr.media}
            />
          ))}
        {!loading && sortedResources.length === 0 && (
          <Text type="P2" className="text-muted-foreground">
            No resources found
          </Text>
        )}
      </div>
    </>
  );
};

const ResourceImage: FC<{ src: string; alt: string }> = (props) => {
  return (
    <img
      className="max-h-[93px] max-w-[91px] object-cover"
      src={props.src}
      alt={props.alt}
    />
  );
};

const ResourceIcon: FC<{ linkType: LinkType }> = (props) => {
  if (props.linkType === LinkType.PDF) {
    return <FileTextOutlineIcon />;
  }
  if ([LinkType.GIF, LinkType.IMAGE].includes(props.linkType)) {
    return <PhotoOutlineIcon />;
  }
  if (
    [LinkType.VIDEO, LinkType.MUX_VIDEO, LinkType.HOSTED_VIDEO].includes(
      props.linkType,
    )
  ) {
    return <VideoOutlineIcon />;
  }
  if (props.linkType === LinkType.LINK) {
    return <LinkIcon />;
  }
  if (props.linkType === LinkType.AUDIO) {
    return <VolumeUpOutlineIcon />;
  }
  if (props.linkType === LinkType.VCARD) {
    return <UserOutlineIcon />;
  }
  return <CircleQuestionOutlineIcon />;
};

const Video: FC<{
  src: string;
}> = (props) => {
  return (
    <VideoPlayer
      hideControls
      playOnHover
      loop
      src={props.src}
      className="max-h-[93px] max-w-[91px] object-cover"
    />
  );
};

const Resource: FC<{ media: MediaFragment; onClick: () => void }> = (props) => {
  const url = getMediaUrl(props.media);
  const linkType = useMemo(() => getLinkType(url), [url]);
  const { data, loading } = useOpenGraphDataQuery({
    variables: { url },
    skip: !(
      !props.media.name?.en ||
      ![
        LinkType.IMAGE,
        LinkType.GIF,
        LinkType.AUDIO,
        LinkType.VCARD,
        LinkType.PDF,
      ].includes(linkType)
    ),
  });
  const alt = data?.OpenGraphData["og:title"] || props.media.name?.en || "";
  const ogImage = data?.OpenGraphData["og:image"];
  const thumbnail = useMemo(() => {
    if (
      [LinkType.IMAGE, LinkType.GIF].includes(linkType) ||
      props.media.thumbnailImageUrl
    ) {
      return (
        <ResourceImage src={props.media.thumbnailImageUrl || url} alt={alt} />
      );
    }
    if ([LinkType.VIDEO, LinkType.MUX_VIDEO].includes(linkType)) {
      return <Video src={url} />;
    }
    if (ogImage) {
      return <ResourceImage src={ogImage} alt={alt} />;
    }
    return (
      <div className="flex h-[93px] w-[91px] items-center justify-center">
        <ResourceIcon linkType={linkType} />
      </div>
    );
  }, [linkType, props.media.thumbnailImageUrl, url, alt, ogImage]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div
      className="flex h-[128px] w-[107px] cursor-pointer flex-col rounded-md bg-muted p-2"
      onClick={props.onClick}
    >
      {thumbnail}
      <Text type="P3" className="mt-auto truncate">
        {data?.OpenGraphData?.["og:title"] || props.media.name?.en || ""}{" "}
      </Text>
    </div>
  );
};

export default Resources;

gql`
  fragment AddMediaResources_TrainingResource on TrainingResource {
    id
    libraryItem {
      createdAt
    }
    media {
      ...Media
    }
  }
  query AddMediaResourcesTrainingResources($search: String!) {
    TrainingResources: AdminTrainingResources(search: $search) {
      ...AddMediaResources_TrainingResource
    }
  }
`;
